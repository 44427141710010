<template>
  <div class="wrapper">
    <h4>{{ titleName }}</h4>
    <el-form :model="form" ref="form" :rules="rules" label-width="140px">
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="施工单位名称" prop="orgName">
            <el-input v-model="form.orgName" placeholder="请输入施工单位名称" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="施工资质" prop="qualification">
            <el-input v-model="form.qualification" placeholder="请输入施工资质" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="施工单位负责人" prop="mainPerson">
            <el-input v-model="form.mainPerson" placeholder="请输入施工单位负责人" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="负责人联系方式" prop="mainPersonTel">
            <el-input v-model="form.mainPersonTel" placeholder="请输入负责人联系方式" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="6">
          <el-form-item label="施工单位地址" prop="city">
            <el-select v-model="form.city" @change="cityChange" placeholder="请选择所在市">
              <el-option v-for="item in cityList" :key="item" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label-width="0" prop="area">
            <el-select v-model="form.area" @change="areaChange" placeholder="请选择所在区">
              <el-option v-for="item in areaList" :key="item" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label-width="0" prop="street">
            <el-select v-model="form.street" @change="streetChange" placeholder="请选择所在街道">
              <el-option v-for="item in streetList" :key="item" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label-width="0" prop="address">
            <el-input v-model="form.address" placeholder="请输入详细地址" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item style="text-align: center" label-width="0">
            <el-button type="primary" icon="fa fa-check" @click="submitEvent('form')">保存</el-button>
            <el-button icon="fa fa-undo" @click="$router.go(-1)">返回</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'AddOrEdit',
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() //选当前时间之前的时间
        },
      },
      cityList: [],
      areaList: [],
      streetList: [],
      titleName: '',
      form: {},
      rules: {
        orgName: [{ required: true, message: '请输入施工单位名称', trigger: 'blur' }],
        qualification: [{ required: true, message: '请输入施工资质', trigger: 'blur' }],
        mainPerson: [{ required: true, message: '请输入施工单位负责人', trigger: 'blur' }],
        mainPersonTel: [
          { required: true, message: '请输入负责人联系方式', trigger: 'blur' },
          { pattern: /^(1[3456789]\d{9})$/, message: '请检查负责人联系方式格式' },
        ],
        city: [{ required: true, message: '请选择所在市', trigger: 'change' }],
        area: [{ required: true, message: '请选择所在区', trigger: 'change' }],
        street: [{ required: true, message: '请选择所在街道', trigger: 'change' }],
        address: [{ required: true, message: '请输入详细地址', trigger: 'blur' }],
      },
    }
  },
  computed: {
    routeType() {
      return this.$route.params.type
    },
  },
  created() {
    this.initView()
  },
  methods: {
    ...mapActions(['addOrSaveChgCompany', 'detailChgCompany', 'getCitysServe', 'getAreasServe', 'getStreetsServe']),
    getCurrentRow(row) {
      this.form.serviceSiteId = row.id
    },
    focusEvent1() {
      this.$refs.xDown1.showPanel()
    },
    initView() {
      this.getDetail()
    },
    getDetail() {
      if (this.routeType == 'add') {
        this.titleName = '添加施工单位'
        this.getCitysList('陕西省')
      } else {
        this.titleName = '修改施工单位'
        this.itemData = JSON.parse(this.$route.query.item)
        let params = {
          id: this.itemData.id,
        }
        let data = null
        let server = 'detailChgCompany'
        this[server](params).then((res) => {
          if (res.code == 200) {
            data = res.data
            this.form = {
              orgName: data.orgName,
              qualification: data.qualification,
              id: data.id,
              mainPerson: data.mainPerson,
              mainPersonTel: data.mainPersonTel,
              city: data.city,
              area: data.area,
              street: data.street,
              address: data.address,
            }
            this.getCitysList('陕西省')
            if (data.city) {
              this.getAreasList(data.city)
            }
            if (data.area) {
              this.getStreetsList(data.area)
            }
          }
        })
      }
    },
 getCitysList(val) {
      let params = {
        parenName: val,
      }
      this.getCitysServe(params).then((res) => {
        if (res.code == 200) {
          this.cityList = res.data
        }
      })
    },
    cityChange(val) {
      if (val) {
        this.getAreasList(val)
      }
    },
    // 获取区县
    getAreasList(val) {
      let params = {
        parenName: val,
      }
      this.getAreasServe(params).then((res) => {
        if (res.code == 200) {
          this.areaList = res.data
        }
      })
    },
    areaChange(val) {
      if (val) {
        this.getStreetsList(val)
      }
    },
    // 获取街道
    getStreetsList(val) {
      let params = {
        parenName: val,
      }
      this.getStreetsServe(params).then((res) => {
        if (res.code == 200) {
          this.streetList = res.data
        }
      })
    },
    streetChange(val) {},
    submitEvent(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {}
          params = this.form
          let server = 'addOrSaveChgCompany'
          this[server](params).then((res) => {
            if (res.code == 200) {
              this.$XModal.message({
                message: this.routeType == 'add' ? '添加成功' : '修改成功',
                status: 'success',
              })
              this.$router.go(-1)
            }
            this.loading = false
          })
        } else {
          return false
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 10px;
  overflow: hidden;
  h4 {
    height: 40px;
    line-height: 40px;
    color: #333;
    padding-left: 20px;
    margin-bottom: 20px;
  }
  .dialog-footer {
    text-align: center;
  }
}
</style>
